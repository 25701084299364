import React, {useEffect, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../../helpers";

function UsersFilter({selectedGroup, setSelectedGroup, selectedKind, setSelectedKind, selectedStatus, setSelectedStatus}) {
    const { t } = useTranslation();
    const [groups, setGroups] = useState([]);
    const kinds = ["guest", "user", "admin", "superadmin"];
    const emailConfirmed = [true, false];

    useEffect(() => {
        performAjaxRequest(
            'GET',
            '/admin/groups.json',
            null,
            {success: response => {
                setGroups(response)
            }})
    }, []);

    return <InputGroup className="justify-content-md-start d-flex flex-column flex-sm-row align-items-md-center mt-4">
        <div className="d-flex justify-content-between justify-content-md-start mb-2 mb-sm-0">
            <Form.Label className="me-3 my-0">{t('group')}</Form.Label>
            <Form.Select size="sm" id="address" className="flex-grow-0 rounded form-select-sm"
                         value={selectedGroup}
                         style={{"width": "75%"}}
                         onChange={e => setSelectedGroup(e.target.value)}>
                <option value="">{t('all')}</option>
                {groups.map((group) => <option value={group.id} key={group.id}>{group.name_with_location}</option>)}
            </Form.Select>
        </div>
        <div className="d-flex justify-content-between justify-content-md-start mb-2 mb-sm-0">
            <Form.Label className="me-3 mx-md-3 my-0">{t('admin.kind')}</Form.Label>
            <Form.Select size="sm" id="teacher" className="flex-grow-0 rounded form-select-sm"
                         value={selectedKind}
                         style={{"width": "75%"}}
                         onChange={e => setSelectedKind(e.target.value)}>
                <option value="">{t('all')}</option>
                {kinds.map((option) => <option value={option} key={option}>{t(`admin.kinds.${option}`)}</option>)}
            </Form.Select>
        </div>
        <div className="d-flex justify-content-between justify-content-md-start mb-2 mb-sm-0">
            <Form.Label className="me-3 mx-md-3 my-0">{t('admin.email')}</Form.Label>
            <Form.Select size="sm" id="teacher" className="flex-grow-0 rounded form-select-sm"
                         value={selectedStatus}
                         style={{"width": "75%"}}
                         onChange={e => setSelectedStatus(e.target.value)}>
                <option value="">{t('all')}</option>
                {emailConfirmed.map((option) => <option value={option} key={option}>{t(`admin.email_confirmed.${option}`)}</option>)}
            </Form.Select>
        </div>
    </InputGroup>
}

export default UsersFilter;