import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../../helpers";

function Email(props) {
    const [emailValidationStatus, setEmailValidationStatus] = useState('valid');
    const { t } = useTranslation();
    const isControlled = !!props.value

    const validateEmail = e => {
        e.preventDefault()
        performAjaxRequest(
            "POST",
            '/users/validate_email.json',
            {email: e.target.value},
            {success: () => {setEmailValidationStatus('valid')},
                    error: (response) => {setEmailValidationStatus(response.responseJSON['error_code'])}})
    }

    return <Form.Group className="mb-3">
        <Form.Label>Email{!props.noRequired && "*"}</Form.Label>
        <Form.Control onChange={props.handleInputChange}
                      value={props.value}
                      onBlur={isControlled ? validateEmail : null}
                      isInvalid={emailValidationStatus !== "valid"}
                      type="email"
                      name="email"
                      size='sm'
                      className="bg-light text-dark"
                      required
                      autoFocus={props.autoFocus || false} />
        <Form.Control.Feedback type="invalid">{isControlled ? t(`errors.messages.${emailValidationStatus}`) : t('errors.messages.invalid_email')}</Form.Control.Feedback>
    </Form.Group>
}

export default Email;
