import React from "react";
import {useTranslation} from "react-i18next";
import Submit from "./fields/submit";
import {performAjaxRequest} from "../../helpers";
import BasicForm from "./basicForm";
import Password from "./fields/password";
import DataPolicy from "./fields/dataPolicy";
import Preferences from "./fields/preferences";
import Back from "./fields/back";

function RegistrationSecondStep(props) {
    const { t } = useTranslation();

    const onSubmit = () => {
        if (props.formData['confirm_token'] !== null && props.formData['confirm_token'] !== undefined) {
            performAjaxRequest(
                "POST",
                `/users/${props.formData['confirm_token']}/confirm_email`,
                { user: props.formData })
        } else {
            performAjaxRequest("POST", '/users.json', { user: props.formData }, {props: props})
        }
    }

    return <BasicForm onSubmit={onSubmit}>
        <Password autoFocus={true} value={props.formData?.password || "" } {...props} />
        <Preferences {...props} />
        <DataPolicy dataPolicyPage={2} {...props} />
        <Submit text={t('finish')} />
        <Back previousPage={0} {...props} />
    </BasicForm>
}

export default RegistrationSecondStep;