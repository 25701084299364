import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../../helpers";

const ReportingFilter = ({selectedMonth, setSelectedMonth, selectedGroup, setSelectedGroup}) => {
    const { t } = useTranslation();
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        performAjaxRequest(
            'GET',
            `/admin/groups.json`,
            null,
            {success: response => setGroups(response)}
        )
    }, []);

    const generateGroupsOptions = () => {
        const options = [];

        options.push(
            <option key={"all"} value="all">
                {t("all")}
            </option>
        )

        options.push(groups?.map(group => (
            <option key={group.id} value={group.id}>
                {group.name_with_location}
            </option>
        )))

        return options
    }

    const generateMonthOptions = () => {
        const options = [];
        const currentDate = new Date();

        const futureDate = new Date();
        futureDate.setMonth(currentDate.getMonth() + 1);
        const futureMonth = futureDate.getMonth();
        const futureYear = futureDate.getFullYear();
        const futureOptionValue = `${futureYear}-${futureMonth + 1 < 10 ? `0${futureMonth  + 1}` : futureMonth  + 1}`;

        options.push(
            <option key={futureOptionValue} value={futureOptionValue}>
                {futureYear}. {t(`short_month_names.${futureMonth}`)}.
            </option>
        );

        for (let i = 0; i < 12; i++) {
            const date = new Date();
            date.setMonth(currentDate.getMonth() - i);
            const month = date.getMonth()
            const year = date.getFullYear();
            const optionValue = `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}`; // Format as YYYY-MM

            options.push(
                <option key={optionValue} value={optionValue}>
                    {year}. {t(`short_month_names.${month}`)}.
                </option>
            );
        }

        return options;
    };

    return <Form.Group className="col-md-6 align-items-start rounded-3 shadow-sm mb-2 p-2 bg-white">
            <Row>
                <Form.Group as={Col}>
                    <Form.Label className="pe-2 m-0">{t('admin.reports.time_range')}</Form.Label>
                    <Form.Select
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        value={selectedMonth}
                        name="selectedMonth"
                        size='sm'
                        className="rounded"
                    >
                        {generateMonthOptions()}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className="pe-2 m-0">{t('group')}</Form.Label>
                    <Form.Select
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        value={selectedGroup}
                        name="selectedGroup"
                        size='sm'
                        className="rounded"
                    >
                        {generateGroupsOptions()}
                    </Form.Select>
                </Form.Group>
            </Row>
    </Form.Group>
};

export default ReportingFilter;
