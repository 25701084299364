import {useState, useEffect} from "react";
import "jquery.csrf";
import Haven from "@chiiya/haven";

export function duration(group) {
    const start = new Date(group.start_time);
    const end = new Date(group.end_time);

    const start_time = `${String(start.getHours()).padStart(2, '0')}:${String(start.getMinutes()).padStart(2, '0')}`
    const end_time = `${String(end.getHours()).padStart(2, '0')}:${String(end.getMinutes()).padStart(2, '0')}`

    return `${start_time} - ${end_time}`;
}

export function monthName(date) {
    let month = new Date(date).toLocaleString('hu', { month: 'long' } )
    return month.charAt(0).toUpperCase() + month.slice(1)
}

export function logout() {
    performAjaxRequest(
        'DELETE',
        '/logout',
        null,
        {success: function () {window.location.reload();}
    });
}

export function emptyFunction() {}

export function useStateWithInitial(initialValue) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return [value, setValue];
}

export function performAjaxRequest(
    method,
    url,
    data,
    options = {},
) {
    const { success, error, props  } = options;

    const handleErrorResponse = (response) => {
        if (typeof error === 'function') {
            error(response);
        } else if (props) {
            props.setFlash({ 'alert': response.responseJSON });
            props.setHideKeys(true);
        }
    };

    const handleSuccessResponse = (response) => {
        if (typeof success === 'function') {
            success(response);
        }
    };

    if (method === 'POST') {
        return $.post(url, data)
            .done(handleSuccessResponse)
            .fail(handleErrorResponse);
    } else {
        return $.ajax({
            type: method,
            url: url,
            data: data,
            dataType: data ? 'json' : null,
        }).done(handleSuccessResponse)
            .fail(handleErrorResponse);
    }
}

export function sortApplicationsByNameAndPaid(applications) {
    return applications?.sort((a, b) => {
        if ((a.paid ? 1 : 0) === (b.paid ? 1 : 0)) {
            return a.user.full_name.localeCompare(b.user.full_name)
        } else {
            return (a.paid ? 1 : 0) < (b.paid ? 1 : 0) ? -1 : 1
        }
    }) || []
}

export function setUpAnalytics(fb_pixel_id) {
    const services = fb_pixel_id ?  [{
        name: 'Facebook Pixel',
        purposes: ['analytics'],
        type: 'facebook-pixel',
        inject: true,
        options: {
            id: fb_pixel_id
        }
    }] : []

    Haven.create({
        services: services,
        notification: {
            styles: {
                background: "#D9D9D9",
                textColor: "#000000",
                linkColor: "#000000",
                buttonBackgroundColor: "#2C60AC",
                buttonBackgroundColorHover: "#002F7A",
                buttonTextColor: "#FFF"
            }
        },
        translations: {
            hu: {
                "notification": {
                    "message": "Ez a weboldal cookie-kat használ a jobb felhasználói élmény érdekében.",
                    "policy": "Részletek.",
                    "accept": "Elfogadom",
                    "decline": "Elutasítom"
                },
                "preferences": {
                    "description": "Ez a weboldal a következő cookie-kat használja:"
                },
                "purposes": {
                    "functional": {
                        "name": "Szükséges cookie-k",
                        "description": "A szükséges cookie-ek elengedhetetlenek az oldal megfelelő működéséhez, úgymint navigálás. Ezek a sütik biztosítják az alapvető funkciókat és a weboldal biztonsági funkcióit, névtelenül."
                    },
                    "analytics": {
                        "name": "Analitikai cookie-k",
                        "description": "Az analitikai sütiket arra használjuk, hogy megértsük, hogyan lépnek kapcsolatba a látogatók a weboldalt. Ezek a cookie-k segítenek információt szolgáltatni a mérőszámokról, úgymint: látogatók száma, visszafordulási arány, forgalom forrása, stb."
                    }
                }
            }
        },
    });
}
