import ContainerFluid from "../containers/container_fluid";
import TooltipWrapper from "../tooltip";
import React, {useRef, useState} from "react";
import Switch from "../switches/switch";
import {useTranslation} from "react-i18next";
import {duration, performAjaxRequest, useStateWithInitial} from "../../helpers";
import TextSmall from "../textSmall";

function EventCard({ application_id: applicationId, subscription_id: subscriptionId, ...props }) {
    const switchRef = useRef()
    const { t } = useTranslation();
    const checked = !!applicationId
    const [isProcessing, setIsProcessing] = useState(false);

    const disabled = () => {
        if (isProcessing) return true

        if (checked) {
            let threshold = new Date(props.event.datetime)
            threshold.setHours(threshold.getHours() - props.settings.application_cancellation_limit)

            return new Date() > threshold
        } else if (props.settings.apply_before_event_limit) {
            let threshold = new Date(props.event.datetime)
            threshold.setHours(threshold.getHours() - props.settings.apply_before_event_limit)

            return new Date() > threshold
        } else {
            return false
        }

    }

    const handleClick = async () => {
        if (disabled()) {
            return; // Ignore clicks while processing or disabled
        }

        setIsProcessing(true);

        if (checked) {
            await performAjaxRequest(
                'DELETE',
                `/applications/${applicationId}`,
                null,
                {success: successCallback,
                         props: props })
        } else {
            await performAjaxRequest(
                'POST',
                '/applications',
                { event_id: props.event.id },
                {success: successCallback,
                        props: props})
        }

        setIsProcessing(false);
    };

    const successCallback = async () => {
        await performAjaxRequest(
            'GET',
            '/applications.json',
            null,
            {success: response => {props.setApplications(response)}})
    };


    return <>
        <ContainerFluid className="justify-content-between bg-dark px-3 py-2"
                        shadow={true}
                        dontWrap={true}>
            <div className="d-flex flex-column m-1">
                <span>{props.event.group.name} {(applicationId && !subscriptionId) && <TooltipWrapper text={t('no_active_pass')}
                                                                                                        icon="bi bi-exclamation-circle text-warning" />} </span>
                <TextSmall text={duration(props.event.group)} />
                <TextSmall text={props.event.group?.short_address} />
                <TextSmall text={props.event.group.teachers_name} />
            </div>

            <Switch ref={switchRef}
                    style={{"transform": "scale(1.3)"}}
                    disabled={disabled() || isProcessing}
                    checked={checked}
                    handleChange={handleClick}/>
        </ContainerFluid>
    </>
}

export default EventCard;