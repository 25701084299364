import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../helpers";
import WsApplicationsEventsModal from "../../components/modals/wsApplicationsEventsModal";
import Autocomplete from "../../components/autocomplete";
import BsIcon from "../../components/bsIcon";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import AccordionWrapper from "../../components/accordionWrapper";
import ApplicationsTable from "../../components/tables/applicationsTable";


function AdminWsApplicationsEventsPage() {
    const { id } = useParams();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const modalRef = useRef();

    const [wsEvent, setWsEvent] = useState(null);
    const [wsApplications, setWsApplications] = useState(null);
    const [applicationsEventsId, setApplicationsEventId] = useState(null);

    const fetchApplications = () => {
        performAjaxRequest(
            'GET',
            `/admin/ws_events/${id}/ws_applications_events.json`,
            null,
            {success:(response) => {
                setWsApplications(response["applications"])
                setWsEvent({id: response["id"],
                                  name: response["name"],
                                  datetime: response["datetime"],
                                  workshop: response["workshop"]})
            }}
        )
    }

    useEffect(() => {
        fetchApplications()
    }, []);

    const openModal = application_id => {
        setApplicationsEventId(application_id)
        modalRef?.current?.showModal()
    }

    const autocompleteSelect = function (event, ui) {
        const autocompleteId = ui.item.id
        const autocompleteValue = ui.item.full_name

        if (wsApplications?.map(a => a.user.full_name).includes(autocompleteValue)) { // already applied
            const application = wsApplications.find(a => a.user.full_name === autocompleteValue);
            if (application) {
                openModal(application.id)
            }
        } else if (autocompleteId) {
            performAjaxRequest(
                'POST',
                `/admin/ws_events/${id}/ws_applications_events.json`,
                {ws_applications_event: { ws_event_id: wsEvent?.id, ws_application_id: autocompleteId} },
                {success: (response) => {
                        fetchApplications()
                        openModal(response.id)
                    },
                    error: () => {alert(t('admin.ws_application_modification_failed'))}
                }
            )
        }
    }

    const modalButton = application => (
        <Button onClick={() => {openModal(application.id)}} variant="white" type="submit">
            {t('admin.modify')}
        </Button>
    )

    return <>
        <WsApplicationsEventsModal ref={modalRef}
                                   wsEventId={id}
                                   wsApplications={wsApplications}
                                   applicationsEventsId={applicationsEventsId}
                                   fetchApplications={fetchApplications}/>

        <div className='d-flex justify-content-between'>
            <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1 text-primary" onClick={() => navigate(-1)}/>
            <AccordionWrapper button={<Button variant="primary" type="submit">{t('admin.new_applications')}</Button>}>
                <Autocomplete url={`/admin/workshops/${wsEvent?.workshop.id}/ws_applications.json`}
                              selectCallback={autocompleteSelect} />
            </AccordionWrapper>
        </div>

        <div className="d-flex flex-column mb-3">
            <small className="text-secondary">{wsEvent?.workshop.name}</small>
            <h4>{wsEvent?.name}</h4>
            <span className="text-secondary">{wsEvent?.datetime}</span>
        </div>

        <ApplicationsTable applications={wsApplications} actionColumn={modalButton} fetchApplications={fetchApplications} />
    </>
}

export default AdminWsApplicationsEventsPage;
