import React from "react";
import {Accordion, AccordionButton} from "react-bootstrap";

function AccordionWrapper({button, children, classNames,rounded = false, arrow = false}) {
    return <Accordion>
        <Accordion.Item eventKey="0" className={`border-0 my-3 ${rounded && "rounded-3 shadow-sm"}`}>
            <AccordionButton bsPrefix={!arrow && "accordion-button-without-arrow"} as="div" className={`d-flex ${classNames} ${!arrow && 'justify-content-end'}`} >
                {button}
            </AccordionButton>
            <Accordion.Body className={`border-0 ${classNames}`}>
                {children}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default AccordionWrapper;
