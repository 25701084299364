import React from "react";
import NotificationSettingCard from "../../components/cards/notificationSettingCard";
import BodyWrapper from "../../components/containers/bodyWrapper";
import {useTranslation} from "react-i18next";

function NotificationSettingsPage(props) {
    const { t } = useTranslation();

    if (!props.preferences) {
        return <div>{t('loading')}</div>;
    }

    return <BodyWrapper>
        { Object.keys(props.preferences).map((setting) => {
            return <NotificationSettingCard key={setting}
                                            name={setting}
                                            checked={props.preferences[setting]}
                                            user_id={props.currentUser.id}
                                            setPreferences={props.setPreferences}/>
        }) }
    </BodyWrapper>
}

export default NotificationSettingsPage;
