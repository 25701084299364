import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import BsIcon from "./bsIcon";

function TooltipWrapper (props) {
    const handleClick = (e) => {
        e.stopPropagation();
    };


    const tooltip = (
        <Tooltip id="tooltip">
            <span className="text-white">{props.text}</span>
        </Tooltip>
    );

    return <OverlayTrigger placement={props.placement || "top"} overlay={tooltip} >
                    <span className="d-inline-block px-2" onClick={handleClick}>
                        <BsIcon icon={props.icon} />
                    </span>
    </OverlayTrigger>
}

export default TooltipWrapper;
