import React, { useState } from "react";
import BsCard from "../cards/bsCard";
import ContainerFluid from "../containers/container_fluid";
import Stepper from "./stepper";

function MultiStepForm({ pages, numSteps, subdomain, defaultFormData,  ...props }) {
    const [page, setPage] = useState( 0);
    const [formData, setFormData] = useState(defaultFormData || {});

    const Page = pages[page]

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const checkboxInputChange = e => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked ? "1" : "0",
        });
    }

    const eventCheckboxInputChange = (id, checked) => {
        let updatedWSEventIds = formData["ws_event_ids"] || [];

        if (checked) {
            updatedWSEventIds.push(id)
        } else {
            updatedWSEventIds.splice(updatedWSEventIds.indexOf(id), 1);
        }

        setFormData({
            ...formData,
            ws_event_ids: updatedWSEventIds
        });
    }

    const preferencesCheckboxInputChange = e => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            preferences: {
                ...formData.preferences,
                [name]: checked ? "1" : "0"
            }
        });
    }

    return (
        <BsCard>
            {page < numSteps && (
                <ContainerFluid className="justify-content-evenly my-2 px-0 py-2">
                    <Stepper numSteps={numSteps} step={page} primaryColor="#2C60AC" />
                </ContainerFluid>
            )}

            <Page
                page={page}
                numSteps={numSteps}
                setPage={setPage}
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                checkboxInputChange={checkboxInputChange}
                eventCheckboxInputChange={eventCheckboxInputChange}
                preferencesCheckboxInputChange={preferencesCheckboxInputChange}
                {...props}
            />
        </BsCard>
    );
}

export default MultiStepForm;