import React from "react";
import {Col, Row} from "react-bootstrap";
import {ArrowLeftCircle} from "react-bootstrap-icons";

function DataPolicyPage(props) {
    const data = props.settings?.data_policy ||
        { company_name: "Klick Computer Hungary Kft.",
          address: "1071 Budapest, Dembinszky utca 44. fsz. 4.",
          tax_number: "12372845-2-43",
          phone_number: "+36 202395904, +36 205335554",
          email: "info@d-dancedress.com",
          person: "Ádám Dorottya" }

    const ownerData = { company_name: "Földvári Ádám",
        address: "1115. Budapest, Hídvég utca 2/a",
        tax_number: "90171608-1-43",
        phone_number: "+36706254624",
        email: "dancepassapp@gmail.com",
        person: "Földvári Ádám" }

    return <>
        <Row>
            <Col className="mb-3">
                <ArrowLeftCircle onClick={() => props.setPage(props.page - 1)} className="fs-1 text-primary"/>
            </Col>
        </Row>
        <h1 style={{textAlign: "center"}}>Adatkezelési tájékoztató</h1>
        <h3>1. AZ ADATKEZELÉSI TÁJÉKOZTATÓ CÉLJA, HATÁLYA, IRÁNYADÓ JOGSZABÁLYOK</h3>
        <p>
            <span>Jelen tájékoztató célja, hogy rögzítse a {data.company_name} által alkalmazott adatvédelmi és adatkezelési elveket, a szervezet adatvédelmi és adatkezelési politikáját, amelyet a szervezet, mint adatkezelő magára nézve kötelező erővel ismer el.</span>
        </p>
        <p>
            <span>A Tájékoztató rendelkezéseinek kialakításakor a szervezet különös tekintettel vette figyelembe az Európai Parlament és a Tanács 2016/679 Rendeletében (“Általános Adatvédelmi Rendelet” vagy “GDPR”), az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (“Infotörvény”), a Polgári Törvénykönyvről szóló 2013. évi V. törvény (“Ptk.”), továbbá a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény (“Grtv.”) rendelkezéseit.</span>
        </p>
        <p>
            <span>Jelen Adatkezelési tájékoztató hatálya a https://dancepass.app címen elérhető weboldalhoz (a továbbiakban: “Weboldal”) kapcsolódó adatkezelésekre terjed ki. Az Adatkezelési tájékoztató visszavonásig érvényes.</span>
        </p>
        <p>
            <span>Az Adatkezelési tájékoztató célja, hogy harmonizálja az adatkezelési tevékenységek tekintetében a szervezet egyéb belső szabályzatainak előírásait a természetes személyek alapvető jogainak és szabadságainak védelme érdekében, valamint biztosítsa a személyes adatok megfelelő kezelését.</span>
        </p>
        <p>
            <span>Az Adatvédelmi tájékoztató kiadásának fontos célja továbbá, hogy megismerésével és betartásával a szervezet képes legyen a természetes személyek adatai kezelését jogszerűen végezni.</span>
        </p>
        <h3>2. AZ ADATKEZELŐ SZEMÉLYE</h3>
        <p>
  <span>
    <strong>Név:</strong>
  </span>
            <span>{ownerData.company_name}</span>
        </p>
        <p>
  <span>
    <strong>Székhely:</strong>
  </span>
            <span>{ownerData.address} (Nincs Ügyfélszolgálat!)</span>
        </p>
        <p>
  <span>
    <strong>Adószám:</strong>
  </span>
            <span>{ownerData.tax_number} </span>
        </p>
        <p>
  <span>
    <strong>Telefon:</strong>
  </span>
            <span>{ownerData.phone_number} </span>
        </p>
        <p>
  <span>
    <strong>E-mail:</strong>
  </span>
            <span>{ownerData.email} </span>
        </p>
        <p>
  <span>
    <strong>Adatvédelmi felelős:</strong>
  </span>
            <span>{ownerData.person} </span>
        </p>
        <p>
            <span>Adatkezelő Magyarországon bejegyzett szervezet.</span>
        </p>
        <p>
            <span>Az Adatkezelő üzemelteti a Weboldalt, amely az Adatkezelő által készített weboldal referenciákat, webes szolgáltatásokat mutatja be.</span>
        </p>
        <h3>3. LÉNYEGES FOGALMAK, MEGHATÁROZÁSOK</h3>
        <ul>
            <li>
                <span>a</span>
                <span>
      <strong>GDPR</strong>
    </span>
                <span>(General Data Protection Regulation) az Európai Unió új Adatvédelmi Rendelete.</span>
            </li>
            <li>
    <span>
      <strong>adatkezelő:</strong>
    </span>
                <span>az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza. Ha az adatkezelés céljait és eszközeit az uniós vagy a tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami jog is meghatározhatja.</span>
            </li>
            <li>
    <span>
      <strong>adatkezelés:</strong>
    </span>
                <span>a személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés, felhasználás, közlés, továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás, korlátozás, törlés, illetve megsemmisítés.</span>
            </li>
            <li>
    <span>
      <strong>adatfeldolgozó:</strong>
    </span>
                <span>az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében személyes adatokat kezel.</span>
            </li>
            <li>
    <span>
      <strong>személyes adat:</strong>
    </span>
                <span>azonosított vagy azonosítható természetes személyre (érintett) vonatkozó bármely információ; azonosítható az a természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például név, szám, helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható.</span>
            </li>
            <li>
    <span>
      <strong>harmadik fél:</strong>
    </span>
                <span>az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak.</span>
                <span>az érintett hozzájárulása: az érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes adatok kezeléséhez.</span>
            </li>
            <li>
    <span>
      <strong>az adatkezelés korlátozása:</strong>
    </span>
                <span>a tárolt személyes adatok megjelölése jövőbeli kezelésük korlátozása céljából.</span>
            </li>
            <li>
    <span>
      <strong>adattörlés:</strong>
    </span>
                <span>az adatok felismerhetetlenné tétele oly módon, hogy a helyreállításuk többé nem lehetséges.</span>
            </li>
            <li>
    <span>
      <strong>adatvédelmi incidens:</strong>
    </span>
                <span>a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi.</span>
            </li>
        </ul>
        <h3>4. AZ ADATKEZELÉS IRÁNYELVEI</h3>
        <p>
            <span>A személyes adatok:</span>
        </p>
        <ul>
            <li>
                <span>kezelését jogszerűen és tisztességesen, valamint az érintett számára átlátható módon kell végezni (“jogszerűség, tisztességes eljárás és átláthatóság”)</span>
            </li>
            <li>
                <span>gyűjtése csak meghatározott, egyértelmű és jogszerű célból történjen, és azokat ne kezeljék ezekkel a célokkal össze nem egyeztethető módon; a 89. cikk (1) bekezdésének megfelelően nem minősül az eredeti céllal össze nem egyeztethetőnek a közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból történő további adatkezelés (“célhoz kötöttség”)</span>
            </li>
            <li>
                <span>az adatkezelés céljai szempontjából megfelelőek és relevánsak kell, hogy legyenek, és a szükségesre kell korlátozódniuk (“adattakarékosság”)</span>
            </li>
            <li>
                <span>pontosnak és szükség esetén naprakésznek kell lenniük; minden ésszerű intézkedést meg kell tenni annak érdekében, hogy az adatkezelés céljai szempontjából pontatlan személyes adatokat haladéktalanul töröljék vagy helyesbítsék (“pontosság”)</span>
            </li>
            <li>
                <span>tárolásának olyan formában kell történnie, amely az érintettek azonosítását csak a személyes adatok kezelése céljainak eléréséhez szükséges ideig teszi lehetővé; a személyes adatok ennél hosszabb ideig történő tárolására csak akkor kerülhet sor, amennyiben a személyes adatok kezelésére a 89. cikk (1) bekezdésének megfelelően közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból kerül majd sor, az e rendeletben az érintettek jogainak és szabadságainak védelme érdekében előírt megfelelő technikai és szervezési intézkedések végrehajtására is figyelemmel (“korlátozott tárolhatóság”)</span>
            </li>
            <li>
                <span>kezelését oly módon kell végezni, hogy megfelelő technikai vagy szervezési intézkedések alkalmazásával biztosítva legyen a személyes adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni védelmet is ideértve (“integritás és bizalmas jelleg”)</span>
            </li>
        </ul>
        <p>
            <span>Az adatkezelő felelős a fentiek megfelelésért, továbbá képesnek kell lennie e megfelelés igazolására (“elszámoltathatóság”).</span>
        </p>
        <h3>5. AZ ADATKEZELÉS CÉLJA</h3>
        <p>
            <span>Az Adatkezelő személyes adatot kizárólag meghatározott célból kezel. Az adatok felvétele és kezelése tisztességesen és törvényesen történik. Az Adatkezelő törekszik arra, hogy csak olyan személyes adat kezelésére kerüljön sor, amely az adatkezelés céljának megvalósulásához elengedhetetlen, a cél elérésére alkalmas. A személyes adat csak a cél megvalósulásához szükséges mértékben és ideig kezelhető.</span>
        </p>
        <h3>6. A KEZELT ADATOK KÖRE</h3>
        <p>
  <span>
    <strong>A WEBOLDAL MŰKÖDTETÉSÉHEZ KAPCSOLÓDÓ ADATKEZELÉS</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>Adatkezelés célja:</strong>
  </span>
            <span>A regisztráció során megadott adatok tárolásával az Adatkezelő kényelmesebb szolgáltatást tud biztosítani (pl. az érintett adatait újabb vásárláskor nem kell ismét megadni)</span>
        </p>
        <p>
  <span>
    <strong>Adatkezelés jogalapja:</strong>
  </span>
            <span>Az érintett hozzájárulása, 6. cikk (1) bekezdés a) pont, az Infotv. 5. § (1) bekezdése, illetve az elektronikus kereskedelemi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény (a továbbiakban: Elker tv.) 13/A. § (3) bekezdése:</span>
        </p>
        <p>
  <span>
    <strong>Kezelt adatok köre:</strong>
  </span>
        </p>
        <div>
            <table>
                <tbody>
                <tr>
                    <td>
          <span>
            <strong>Személyes adat</strong>
          </span>
                    </td>
                    <td>
          <span>
            <strong>Az adatkezelés célja</strong>
          </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Felhasználói név</span>
                    </td>
                    <td>
                        <span>Azonosítás, a regisztráció lehetővé tétele.</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Jelszó</span>
                    </td>
                    <td>
                        <span>A felhasználói fiókba történő biztonságos belépést szolgálja.</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Vezeték-és keresztnév</span>
                    </td>
                    <td>
                        <span>A kapcsolatfelvételhez, a vásárláshoz és a szabályszerű számla kiállításához szükséges.</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Kapcsolattartó neve</span>
                    </td>
                    <td>
                        <span>Kapcsolattartás</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>E-mail cím</span>
                    </td>
                    <td>
                        <span>Kapcsolattartás</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Telefonszám</span>
                    </td>
                    <td>
                        <span>Kapcsolattartás, a számlázással, vagy a szállítással kapcsolatos kérdések hatékonyabb egyeztetése.</span>
                    </td>
                </tr>
                <tr>
                    <td> </td>
                    <td> </td>
                </tr>
                <tr>
                    <td>
                        <span>A vásárlás/regisztráció </span>
                    </td>
                    <td>
                        <span>Technikai művelet végrehajtása.</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <p>
  <span>
    <strong>Az érintettek köre:</strong>
  </span>
            <span>A webshop weboldalon regisztrált valamennyi érintett</span>
        </p>
        <p>
  <span>
    <strong>Az adatkezelés időtartama, az adatok törlésének határideje:</strong>
  </span>
            <span>A regisztráció törlését követő 30 naptári napon belül. Kivéve a számviteli bizonylatok esetében, hiszen a számvitelről szóló 2000. évi C. törvény 169. § (2) bekezdése alapján 8 évig meg kell őrizni ezeket az adatokat.</span>
        </p>
        <p>
            <span>(A könyvviteli elszámolást közvetlenül és közvetetten alátámasztó számviteli bizonylatot (ideértve a főkönyvi számlákat, az analitikus, illetve részletező nyilvántartásokat is), legalább 8 évig kell olvasható formában, a könyvelési feljegyzések hivatkozása alapján visszakereshető módon megőrizni.)</span>
        </p>
        <p>
  <span>
    <strong>Az adatok megismerésére jogosult lehetséges adatkezelők személye, a személyes adatok címzettjei:</strong>
  </span>
            <span>A személyes adatokat az adatkezelő munkatársai kezelhetik, a fenti alapelvek tiszteletben tartásával.</span>
        </p>
        <p>
  <span>
    <strong>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése:</strong>
  </span>
        </p>
        <ul>
            <li>
                <span>Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, és</span>
            </li>
            <li>
                <span>tiltakozhat az ilyen személyes adatok kezelése ellen, valamint</span>
            </li>
            <li>
                <span>az érintettnek joga van az adathordozhatósághoz, továbbá a hozzájárulás bármely időpontban történő visszavonásához.</span>
            </li>
        </ul>
        <p>
  <span>
    <strong>A személyes adatok törlését vagy módosítását az alábbi módokon lehet kezdeményezni:</strong>
  </span>
        </p>
        <p>
            <span>A következő adatok módosítását lehet elvégezni a weboldalakon: jelszó, vezetéknév és keresztnév, e-mail cím, telefonszám, számlázási cím, szállítási cím, kapcsolattartó neve. A személyes adatok törlését, vagy módosítását az alábbi módokon tudja érintett kezdeményezni:</span>
        </p>
        <ul>
            <li>
                <span>postai úton: {ownerData.address}</span>
            </li>
            <li>
                <span>e-mail útján a {ownerData.email}  címen</span>
            </li>
        </ul>
        <p>
            <span>–</span>
        </p>
        <p>
  <span>
    <strong>KAPCSOLATTARTÁS</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>Adatkezelés célja:</strong>
  </span>
            <span>kapcsolatfelvétel, kapcsolattartás, információ közlés, információ kérés.</span>
        </p>
        <p>
  <span>
    <strong>Adatkezelés jogalapja:</strong>
  </span>
            <span>érintett önkéntes hozzájárulása, GDPR 6. cikk (1) bekezdés a) pont.</span>
        </p>
        <p>
  <span>
    <strong>Kezelt adatok köre:</strong>
  </span>
            <span>név, e-mail cím, telefonszám, és szabad szöveges mezőben megadott esetleges személyes adatok.</span>
        </p>
        <p>
  <span>
    <strong>Adatok törlésének határideje:</strong>
  </span>
            <span>az utolsó kapcsolatfelvételtől számított 1 év vagy a hozzájárulás visszavonása</span>
        </p>
        <p>
  <span>
    <strong>ÓRÁRA JELENTKEZÉS</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>Adatkezelés célja:</strong>
  </span>
            <span>táncórák látogatása, táncórára történő jelentkezés.</span>
        </p>
        <p>
  <span>
    <strong>Adatkezelés jogalapja:</strong>
  </span>
            <span>érintett önkéntes hozzájárulása, GDPR 6. cikk (1) bekezdés a) pont.</span>
        </p>
        <p>
  <span>
    <strong>Kezelt adatok köre:</strong>
  </span>
            <span>név, e-mail cím, telefonszám, életkor, lakhely, korábbi tánc tapasztalat, tánctípus iránti érdeklődés, táncóra napja és ideje, táncóra helyszíne, megjegyzésben esetlegesen megadott személyes adatok.</span>
        </p>
        <p>
  <span>
    <strong>Adatok törlésének határideje:</strong>
  </span>
            <span>az utolsó kapcsolatfelvételtől számított 1 év vagy a hozzájárulás visszavonása.</span>
        </p>
        <h3>7. AZ ADATKEZELÉS MÓDJA</h3>
        <p>
            <span>Az érintett adatait Adatkezelő a saját szerverein, valamint ideiglenesen az Adatkezelő számítógépein tárolja. Az érintettek személyes adatainak kezeléséhez kizárólag az Adatkezelőnek van lehetősége.</span>
        </p>
        <p>
            <span>Az adatszolgáltatás minden esetben önkéntes, azaz az érintett szabadon dönthet arról, hogy megadja-e a kért személyes adatait. Amennyiben ahhoz az érintett hozzájárul, az Adatkezelő az adatokat a hatályos jogszabályoknak megfelelően, valamint az érintettek hozzájárulásának korlátai között kezeli.</span>
        </p>
        <p>
            <span>A kezelt személyes adatok jogosulatlan felhasználása és az azzal kapcsolatos visszaélések elkerülése érdekében Adatkezelő kiterjedt műszaki és üzemeltetési biztonsági intézkedéseket alkalmaz. Biztonsági eljárásainkat rendszeresen ellenőrizzük és a technológiai fejlődéssel összhangban fejlesztjük.</span>
        </p>
        <h3>8. TECHNIKAI ADATOK ÉS COOKIE-K KEZELÉSE</h3>
        <p>
            <span>Mivel a természetes személyek összefüggésbe hozhatók az általuk használt készülékek, alkalmazások, eszközök és protokollok által rendelkezésre bocsátott online azonosítókkal, például IP-címekkel és cookie-azonosítókkal, ezért ezek az adatok egyéb információkkal összekapcsolva alkalmasak és felhasználhatók a természetes személyek profiljának létrehozására és az adott személy azonosítására.</span>
        </p>
        <p>
            <span>A cookie-k alkalmasak továbbá arra, hogy megjegyezzék a beállításokat, így nem kell azokat a felhasználónak újra rögzítenie, ha egy új oldalra lép, emlékeznek a korábban bevitt adatokra, ezért azokat nem kell újra begépelni, elemzik a honlap használatát annak érdekében, hogy az így nyert információk felhasználásával végrehajtott fejlesztések eredményeként az a lehető legnagyobb mértékben a felhasználó elvárásai szerint működjön, a felhasználó könnyen megtalálja a keresett információt, és figyelemmel kísérik hirdetéseink hatékonyságát.</span>
        </p>
        <p>
            <span>Amennyiben a Weboldalon az Adatkezelő külső webes szolgáltatások segítségével jelenít meg különféle tartalmakat, az néhány olyan süti tárolását eredményezheti, melyeket nem az Adatkezelő felügyel, így nincs befolyása arra, hogy ezek a weboldalak, illetve külső domainek milyen adatokat gyűjtenek. Ezen cookie-król az adott szolgáltatásra vonatkozó szabályzatok adnak tájékoztatást. A felhasználó beállíthatja webes keresőjét úgy, hogy elfogadjon minden sütit, elutasítsa mindet, vagy értesítse a felhasználót, ha süti érkezik a gépére. A beállítási lehetőségek általában a böngésző “Opciók” vagy “Beállítások” menüpontjában találhatók. Az angol nyelvű www.aboutcookies.org weboldalon található részletes tájékoztató szintén segít a különböző böngészőkben történő beállításokban.</span>
        </p>
        <p>
  <span>
    <strong>A honlap által használt cookie-k főbb jellemzői:</strong>
  </span>
        </p>
        <ul>
            <li>
                <span>
                  <strong>A Weboldal működéshez szükséges cookie-k:</strong>
                </span>
                <span>Ezek a cookie-k a weboldal használatához nélkülözhetetlenek, és lehetővé teszik a weboldal alapvető funkcióinak használatát. Ezen típusú cookie-k élettartama kizárólag a munkamenet idejére korlátozódik.</span>
            </li>
            {props.settings?.facebook_pixel_id &&
                <li>
                    <span>
                      <strong>Analitikai cookie-k:</strong>
                    </span>
                    <span>Az analitikai sütiket arra használjuk, hogy megértsük, hogyan lépnek kapcsolatba a látogatók a weboldalt. Ezek a cookie-k segítenek információt szolgáltatni a mérőszámokról, úgymint: látogatók száma, visszafordulási arány, forgalom forrása, stb.</span>
                </li>
            }
        </ul>
        <h3>9. ADATTOVÁBBÍTÁS</h3>
        <p>
            <span>Az Adatkezelő személyes adatot csak akkor továbbít harmadik személy részére, ha ahhoz az érintett egyértelműen – a továbbított adatkör és az adattovábbítás címzettje ismeretében – hozzájárult, vagy az adattovábbításra törvény felhatalmazást ad. Az Adatkezelő az adattovábbításokat minden esetben dokumentálja, és az adattovábbításokról nyilvántartást vezet.</span>
        </p>
        <h3>10. ADATFELDOLGOZÁS</h3>
        <p>
            <span>Az Adatkezelő a tevékenysége ellátásához jogosult adatfeldolgozót igénybe venni. Az adatfeldolgozók önálló döntést nem hoznak, kizárólag az Adatkezelővel kötött szerződés, és a kapott utasítások szerint jogosultak eljárni. Az Adatkezelő ellenőrzi az adatfeldolgozók munkáját. Az adatfeldolgozók további adatfeldolgozó igénybe vételére csak az Adatkezelő hozzájárulásával jogosultak.</span>
        </p>
        <p>
  <span>
    <strong>Az Adatkezelő által igénybe vett adatfeldolgozók:</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>WEBTÁRHELY SZOLGÁLTATÁSSAL ÖSSZEFÜGGŐ ADATFELDOLGOZÓ TEVÉKENYSÉG</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó megnevezése:</strong>
  </span>
            <span>{data.company_name}</span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó székhelye:</strong>
  </span>
            <span>{data.address} (Nincs Ügyfélszolgálat!)</span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó adószáma:</strong>
  </span>
            <span>{data.tax_number} </span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó telefonszáma:</strong>
  </span>
            <span>{data.phone_number} </span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó e-mail címe:</strong>
  </span>
            <span>{data.email} </span>
        </p>
        <p>
            <span>A weboldalon az érintett által megadott valamennyi személyes adat feldolgozása, a webhely megfelelő működtetése érdekében.</span>
        </p>
        <p>
            <span>Az adatkezelés időtartama, az adatok törlésének határideje: A Szolgáltató és a Tárhely-szolgáltató közötti megállapodás megszűnéséig, vagy az érintettnek a Tárhely-szolgáltató felé intézett törlési kérelméig tart.</span>
        </p>
        <p>
            <span>–</span>
        </p>
        <p>
  <span>
    <strong>WEBOLDAL ÜZEMELTETÉSÉVEL ÖSSZEFÜGGŐ ADATFELDOLGOZÓI TEVÉKENYSÉG</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó megnevezése:</strong>
  </span>
            <span>{data.company_name}</span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó székhelye:</strong>
  </span>
            <span>{data.address} (Nincs Ügyfélszolgálat!)</span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó adószáma:</strong>
  </span>
            <span>{data.tax_number} </span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó telefonszáma:</strong>
  </span>
            <span>{data.phone_number} </span>
        </p>
        <p>
  <span>
    <strong>Az adatfeldolgozó e-mail címe:</strong>
  </span>
            <span>{data.email} </span>
        </p>
        <p>
            <span>Az Adatfeldolgozó az Adatkezelővel kötött írásbeli szerződés alapján bizonyos időközönként karbantartja a Weboldalt, annak adatbázisát biztonsági okokból lementi.</span>
        </p>
        <h3>11. KÜLSŐ SZOLGÁLTATÓK</h3>
        <p>
            <span>A Weboldal üzemeltetése során az Adatkezelő külső szolgáltatókat vesz igénybe, amely külső szolgáltatókkal az Adatkezelő együttműködik.</span>
        </p>
        <p>
            <span>A Külső szolgáltatók rendszereiben kezelt személyes adatok tekintetében a külső szolgáltatók saját adatvédelmi szabályzatában foglaltak az irányadók. Az Adatkezelő minden tőle telhetőt megtesz annak érdekében, hogy a külső szolgáltató a részére továbbított személyes adatokat a jogszabályoknak megfelelőn kezelje, és azokat kizárólag a Felhasználó által meghatározott vagy a jelen tájékoztatóban alább rögzített célra használja fel.</span>
        </p>
        <p>
            <span>Az Adatkezelő a külső szolgáltatók számára végzett adattovábbításról a jelen Tájékoztató keretében tájékoztatja a Felhasználókat.</span>
        </p>
        <p>
  <span>
    <strong>Külső szolgáltatók:</strong>
  </span>
        </p>
        <ul>
            <li>
                <span>Google, Mountain View, Kalifornia, Egyesült Államok (Google Drive adattárolás, Google Analytics aggregált adatelemzés, Google Adwords online hirdetés, Google Tagmanager mérőkód elhelyezés, Google+ és Youtube social felületek használata stb.)</span>
            </li>
        </ul>
        <h3>12. ADATBIZTONSÁG, AZ ADATOK MEGISMERÉSE</h3>
        <p>
            <span>Az Adatkezelő gondoskodik az adatok biztonságáról, megteszi azokat a technikai és szervezési intézkedéseket és kialakítja azokat az eljárási szabályokat, amelyek az irányadó jogszabályok, adat- és titokvédelmi szabályok érvényre juttatásához szükségesek. Az Adatkezelő az adatokat megfelelő intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.</span>
        </p>
        <p>
            <span>Az Adatkezelő az általa kezelt adatokat az irányadó jogszabályoknak megfelelően tartja nyilván, biztosítva, hogy az adatokat csak azok a munkavállalók, és egyéb az Adatkezelő érdekkörében eljáró személyek (adatfeldolgozók) ismerhessék meg, akiknek erre munkakörük, feladatuk ellátása érdekében szükségük van. Az adatokat a munkavállaló szervezetén belül csak naplózás mellett lehet megismerni. Az adatkezelő munkavállalói egyedi kereséseket, az adatokon egyedi műveleteket csak a Felhasználó kérésére végeznek, vagy abban az esetben, ha ez a szolgáltatás nyújtása érdekében szükséges.</span>
        </p>
        <h3>13. AZ ADATKEZELÉS IDŐTARTAMA</h3>
        <p>
  <span>
    <strong>Az Adatkezelő a személyes adatot törli, ha</strong>
  </span>
        </p>
        <ul>
            <li>
    <span>
      <strong>kezelése jogellenes:</strong>
    </span>
                <span>Amennyiben kiderül, hogy az adatok kezelése jogellenesen történik, az Adatkezelő a törlést haladéktalanul végrehajtja.</span>
            </li>
            <li>
    <span>
      <strong>az érintett kéri</strong>
    </span>
                <span>(a jogszabályon alapuló adatkezelések kivételével): Az érintett önkéntes hozzájárulása alapján kezelt adatok törlését az érintett kérheti. Ez esetben az Adatkezelő az adatokat törli.</span>
            </li>
            <li>
    <span>
      <strong>az adat hiányos vagy téves</strong>
    </span>
                <span>– és ez az állapot jogszerűen nem orvosolható –, feltéve, hogy a törlést törvény nem zárja ki.</span>
            </li>
            <li>
    <span>
      <strong>az adatkezelés célja megszűnt</strong>
    </span>
                <span>, vagy az adatok tárolásának törvényben meghatározott határideje lejárt. Mivel az érintett részére az Adatkezelő folyamatos szolgáltatást nyújt, a felek kapcsolata időhatárhoz nem kötött. Mindezek alapján – az érintett kérésének hiányában – az Adatkezelő mindaddig kezeli az adatokat, amig az Adatkezelő és az érintett közötti kapcsolat fennáll, és ameddig az adatkezelő az érintett számára szolgáltatást nyújthat. Minden egyéb adatot az Adatkezelő töröl, ha nyilvánvaló, hogy az adatok felhasználására a jövőben nem kerül sor, vagyis az adatkezelés célja megszűnt.</span>
            </li>
            <li>
    <span>
      <strong>azt a bíróság vagy a Nemzeti Adatvédelmi és Információszabadság Hatóság elrendelte:</strong>
    </span>
                <span>Amennyiben bíróság vagy a Nemzeti Adatvédelmi és Információszabadság jogerősen elrendeli az adatok törlését, a törlést az Adatkezelő végrehajtja. Törlés helyett az Adatkezelő – az érintett tájékoztatása mellett – zárolja a személyes adatot, ha az érintett ezt kéri, vagy ha a rendelkezésére álló információk alapján feltételezhető, hogy a törlés sértené az érintett jogos érdekeit. Az így zárolt személyes adat kizárólag addig kezelhető, ameddig fennáll az az adatkezelési cél, amely a személyes adat törlését kizárta. Az Adatkezelő megjelöli az általa kezelt személyes adatot, ha az érintett vitatja annak helyességét vagy pontosságát, de a vitatott személyes adat helytelensége vagy pontatlansága nem állapítható meg egyértelműen. A jogszabály által elrendelt adatkezelések esetében az adatok törlésére a jogszabály rendelkezése az irányadó. A törlés esetén az Adatkezelő az adatokat személyazonosításra alkalmatlanná teszi. Amennyiben jogszabály azt előírja, az Adatkezelő a személyes adatot tartalmazó adathordozót megsemmisíti.</span>
            </li>
        </ul>
        <h3>14. ÜGYFÉLKAPCSOLATOK</h3>
        <ul>
            <li>
                <span>Amennyiben az adatkezelő szolgáltatásaink igénybevétele során kérdés merülne fel, esetleg problémája lenne az érintettnek, a honlapon megadott módokon (telefon, e-mail, közösségi oldalak stb.) kapcsolatba léphet az adatkezelővel.</span>
            </li>
            <li>
                <span>Adatkezelő a beérkezett e-maileket, üzeneteket, telefonon, Facebook-on stb. megadott adatokat az érdeklődő nevével és e-mail címével, valamint más, önként megadott személyes adatával együtt, az adatközléstől számított legfeljebb 2 év elteltével törli.</span>
            </li>
            <li>
                <span>E tájékoztatóban fel nem sorolt adatkezelésekről az adat felvételekor adunk tájékoztatást.</span>
            </li>
            <li>
                <span>Kivételes hatósági megkeresésre, illetőleg jogszabály felhatalmazása alapján más szervek megkeresése esetén a Szolgáltató köteles tájékoztatás adására, adatok közlésére, átadására, illetőleg iratok rendelkezésre bocsátására.</span>
            </li>
            <li>
                <span>A Szolgáltató ezen esetekben a megkereső részére – amennyiben az a pontos célt és az adatok körét megjelölte – személyes adatot csak annyit és olyan mértékben ad ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.</span>
            </li>
        </ul>
        <h3>15. AZ ADATKEZELÉSSEL KAPCSOLATOS JOGOK</h3>
        <p>
            <span>Az érintettek számára minden, az adatkezelési esethez tartozó jogalaphoz kapcsolódó jog gyakorlásának lehetősége biztosított. Az érintett által az egyes jogok gyakorlása kapcsán benyújtott kérelmeket írásban teheti meg:</span>
        </p>
        <ul>
            <li>
                <span>postai úton: {ownerData.address} címre címezve, vagy</span>
            </li>
            <li>
                <span>elektronikus úton Adatkezelő e-mail címén: {ownerData.email} .</span>
            </li>
        </ul>
        <p>
            <span>Az Adatkezelő indokolatlan késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított egy hónapon belül tájékoztatja az érintettet a megadott jogok gyakorlása céljából Adatkezelő részére megküldött kérelem nyomán hozott intézkedésekről. Szükség esetén, figyelembe véve a kérelem összetettségét és a kérelmek számát, ez a határidő további két hónappal meghosszabbítható.</span>
        </p>
        <p>
            <span>A határidő meghosszabbításáról Adatkezelő a késedelem okainak megjelölésével a kérelem kézhezvételétől számított egy hónapon belül tájékoztatja az érintettet. Ha az érintett elektronikus úton nyújtotta be a kérelmet, a tájékoztatás elektronikus úton kerül megadásra, kivéve, ha az érintett azt másként kéri. Ha az Adatkezelő nem tesz intézkedéseket az érintett kérelme nyomán, késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított egy hónapon belül tájékoztatja az érintettet az intézkedés elmaradásának okairól, valamint arról, hogy az érintett panaszt nyújthat be valamely felügyeleti hatóságnál, és élhet bírósági jogorvoslati jogával.</span>
        </p>
        <p>
  <span>
    <strong>Tájékoztatáshoz való jog és hozzáféréshez való jog</strong>
  </span>
        </p>
        <p>
            <span>Az érintettnek joga van arra, hogy az Adatkezelő által tárolt személyes adatait és a kezelésükkel kapcsolatos információkat megismerhesse, bármikor kikérje, ellenőrizze, hogy az Adatkezelő milyen adatot tart nyilván róla, továbbá jogosult arra, hogy a személyes adatokhoz hozzáférést kapjon.</span>
        </p>
        <p>
            <span>Hozzáférési jog gyakorlása esetén a tájékoztatás a következő adatokra terjed ki:</span>
        </p>
        <ol>
            <li>
                <span>adatkezelés célja, ideje, jogalapja,</span>
            </li>
            <li>
                <span>az érintett személyes adatok kategóriái,</span>
            </li>
            <li>
                <span>adattovábbítás címzettjei, amennyiben hatósági megkeresésre került sor, arról történő tájékoztatás, hogy mely felvételek mely hatóság részére kerültek továbbításra,</span>
            </li>
            <li>
                <span>az érintett helyesbítéshez, törléshez, az adatkezelés korlátozásához és tiltakozáshoz való joga,</span>
            </li>
            <li>
                <span>az érintett (felügyeleti hatósághoz címzett) panasz benyújtásához való joga,</span>
            </li>
            <li>
                <span>adatforrás megjelölése: közvetlenül az érintettől gyűjtött adatok,</span>
            </li>
            <li>
                <span>az automatizált döntéshozatal ténye (ideértve a profilalkotást is), az alkalmazott logika, az adatkezelés jelentősége és az érintettre vonatkozó várható következménye.</span>
            </li>
        </ol>
        <p>
            <span>Az Adatkezelő az adatok másolatát első alkalommal ingyenesen biztosítja az érintett részére. Az érintett által kért további másolatokért az Adatkezelő az adminisztratív költségeken alapuló, észszerű mértékű díjat számíthat fel. Ha az érintett elektronikus úton kéri a másolat kiadást úgy, az információkat e-mailben, széles körben használt elektronikus formátumban bocsátja Adatkezelő az érintett rendelkezésére. Az érintett a tájékoztatást követően, amennyiben az adatkezeléssel, a kezelt adatok helyességével nem ért egyet úgy kérelmezheti a rá vonatkozó személyes adatok helyesbítését, kiegészítését, törlését, kezelésének korlátozását, illetve a 16. pontban meghatározott eljárást kezdeményezhet.</span>
        </p>
        <p>
  <span>
    <strong>Helyesbítés, kiegészítés joga</strong>
  </span>
        </p>
        <p>
            <span>Az érintett kérheti a róla kezelt, rá vonatkozó pontatlan személyes adatok helyesbítését és a hiányos adatok kiegészítését.</span>
        </p>
        <p>
  <span>
    <strong>Törléshez való jog</strong>
  </span>
        </p>
        <p>
            <span>Az érintett az alábbi indokok valamelyikének fennállása esetén jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat:</span>
        </p>
        <ol>
            <li>
                <span>a személyes adatokra már nincs szükség abból a célból, amelyből azokat az Adatkezelő gyűjtötte vagy más módon kezelte;</span>
            </li>
            <li>
                <span>az érintett visszavonja az adatkezelés alapját képező hozzájárulását, és az adatkezelésnek nincs más jogalapja;</span>
            </li>
            <li>
                <span>az érintett saját helyzetével kapcsolatos okokból tiltakozik az adatkezelés ellen, és nincs jogszerű ok az adatkezelésre;</span>
            </li>
            <li>
                <span>az érintett tiltakozik a rá vonatkozó személyes adatok közvetlen üzletszerzés célból történő adatainak kezelése ellen, ideértve a profilalkotást is, amennyiben az a közvetlen üzletszerzéshez kapcsolódik;</span>
            </li>
            <li>
                <span>a személyes adatokat az Adatkezelő jogellenesen kezeli;</span>
            </li>
            <li>
                <span>a személyes adatok gyűjtésére közvetlenül gyermekeknek kínált, információs társadalommal összefüggő szolgáltatások kínálásával kapcsolatosan került sor.</span>
            </li>
        </ol>
        <p>
  <span>
    <strong>Az adatkezelés korlátozásához való jog</strong>
  </span>
        </p>
        <p>
            <span>Az érintett kérésére Adatkezelő korlátozza az adatkezelést, ha az alábbi feltételek valamelyike teljesül:</span>
        </p>
        <ol>
            <li>
                <span>az érintett vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, a személyes adatok pontosságának ellenőrzését;</span>
            </li>
            <li>
                <span>az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését, és ehelyett kéri azok felhasználásának korlátozását;</span>
            </li>
            <li>
                <span>az Adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de az érintett igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy</span>
            </li>
            <li>
                <span>az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az Adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival szemben.</span>
            </li>
        </ol>
        <p>
            <span>Ha az adatkezelés korlátozás alá esik, a személyes adatokat a tárolás kivételével csak az érintett hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más természetes vagy jogi személy jogainak védelme érdekében, vagy az Unió, illetve valamely tagállam fontos közérdekéből lehet kezelni. Adatkezelő az érintettet az adatkezelés korlátozásának feloldásáról előzetesen tájékoztatja.</span>
        </p>
        <p>
  <span>
    <strong>Tiltakozás joga</strong>
  </span>
        </p>
        <p>
            <span>Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak az Adatkezelő jogos érdekeinek érvényesítéséhez szükséges kezelése ellen. Tiltakozás esetén az Adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha azt olyan kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek az érintett érdekeivel, jogaival és szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez kapcsolódnak. Ha a személyes adatok kezelése közvetlen üzletszerzés érdekében történik, az érintett jogosult arra, hogy bármikor tiltakozzon a rá vonatkozó személyes adatok e célból történő kezelése ellen, ideértve a profilalkotást is, amennyiben az a közvetlen üzletszerzéshez kapcsolódik.</span>
        </p>
        <h3>16. AZ ADATKEZELÉSSEL KAPCSOLATOS JOGÉRVÉNYESÍTÉSI LEHETŐSÉG</h3>
        <p>
  <span>
    <strong>Nemzeti Adatvédelmi és Információszabadság Hatóság</strong>
  </span>
        </p>
        <p>
  <span>
    <strong>Postacím:</strong>
  </span>
            <span>1530 Budapest, Pf.: 5.</span>
        </p>
        <p>
  <span>
    <strong>Cím:</strong>
  </span>
            <span>1125 Budapest, Szilágyi Erzsébet fasor 22/c</span>
        </p>
        <p>
  <span>
    <strong>Telefon:</strong>
  </span>
            <span>+36 (1) 391-1400</span>
        </p>
        <p>
  <span>
    <strong>Fax:</strong>
  </span>
            <span>+36 (1) 391-1410</span>
        </p>
        <p>
  <span>
    <strong>E-mail:</strong>
  </span>
            <span>ugyfelszolgalat (kukac) naih.hu</span>
        </p>
        <p>
  <span>
    <strong>Web:</strong>
  </span>
            <span>https://naih.hu</span>
        </p>
    </>
}

export default DataPolicyPage;