import React, {forwardRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import Modal from "./modal";
import {performAjaxRequest} from "../../helpers";
import Name from "../forms/fields/name";
import Email from "../forms/fields/email";
import Gender from "../forms/fields/gender";
import Submit from "../forms/fields/submit";
import BasicForm from "../forms/basicForm";

const GuestUserModal = forwardRef(({eventId, fetchApplications, ...props}, ref) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({kind: 'guest', event_id: eventId});

    const onClick = () => {
        $('button.btn-close').click()
        performAjaxRequest(
            'POST',
            '/users.json',
            { user: formData },
            {success: fetchApplications, props: props}
        )
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return <Modal ref={ref} className="p-4" id="guestUserModal" title={t('admin.guest_registration')} >
        <BasicForm onSubmit={onClick} >
            <Name formData={formData} handleInputChange={handleInputChange} />
            <Email value={formData.email || ""} formData={formData} handleInputChange={handleInputChange} />
            <Gender noJoker={true} admin={true} formData={formData} handleInputChange={handleInputChange}  />
            <Submit text={t('admin.create')} />
        </BasicForm>
    </Modal>
    }
);

export default GuestUserModal;
