import React from "react";
import RegistrationFirstStep from "../components/forms/registrationFirstStep";
import RegistrationSecondStep from "../components/forms/registrationSecondStep";
import DataPolicyPage from "./dataPolicyPage";
import MultiStepForm from "../components/forms/multiStepForm";
import {useLocation} from "react-router-dom";

function RegistrationPage(props) {
    const location = useLocation();
    const pages = [RegistrationFirstStep, RegistrationSecondStep, DataPolicyPage];

    const queryParams = new URLSearchParams(location.search);
    const defaultFormData = {
        confirm_token: queryParams.get('confirm_token'),
        first_name: queryParams.get('first_name'),
        last_name: queryParams.get('last_name'),
        email: queryParams.get('email'),
        gender: queryParams.get('gender'),
        kind: 'user',
        preferences: {event_notification: "1", subscription_notification: "1"}
    }

    return <MultiStepForm pages={pages} numSteps={2} defaultFormData={defaultFormData} {...props}/>
}

export default RegistrationPage;