import React, { useEffect, useRef, useState } from 'react';
import 'jquery-ui/ui/widgets/autocomplete';
import { Form, InputGroup } from "react-bootstrap";
import BsIcon from "./bsIcon";
import { useTranslation } from "react-i18next";
import {performAjaxRequest} from "../helpers";

const Autocomplete = ({ url, sourceKey, selectCallback }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        $(inputRef.current).autocomplete({
            source: function (request, response) {
                performAjaxRequest(
                    'GET',
                    url,
                    { term: request.term },
                    { success: function (data) {
                        if (sourceKey) {
                            response(data[sourceKey]);
                        } else {
                            response(data)
                        }
                    },
                    error: function () {
                        response([]);
                    }})
            },
            minLength: 2,
            select: function (event, ui) {
                setInputValue(ui.item.full_name);
                if (selectCallback) {
                    selectCallback(event, ui);
                }
                return false;
            },
            create: function () {
                $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li>")
                        .append(
                            `<div style='line-height: 1;' class='py-2'>${item.full_name}<br><span class='text-secondary small'>${item.email}</span></div>`
                        )
                        .appendTo(ul);
                };
            }
        });

        return () => {
            $(inputRef.current).autocomplete('destroy');
        };
    }, [url, selectCallback]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="basic-addon1">
                <BsIcon icon="search" />
            </InputGroup.Text>
            <Form.Control
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                placeholder={t('search')}
                aria-label={t('search')}
                aria-describedby="basic-addon1"
            />
        </InputGroup>
    );
};

export default Autocomplete;
