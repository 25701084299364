import React from "react";
import {Nav} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BsIcon from "../bsIcon";
import FooterItem from "./footerItem";

function MobileFooter(props) {
    const location = useLocation();
    const { t } = useTranslation();

    const profileItemActive = location.pathname.startsWith(`/users/${props.currentUser?.id}`) && !location.pathname.endsWith('groups')

    if (location.pathname.startsWith("/admin")) {
        return null;
    } else {
        return <Nav className="navbar navbar-dark fixed-bottom bg-dark d-block d-md-none shadow">
            <div className="container-fluid justify-content-evenly">
                {props.flippers.workshops_ui && <FooterItem to="/workshops" icon="calendar2-heart"/>}
                {props.currentUser ? <>
                    <FooterItem to="/events" icon="calendar-week"/>
                    <FooterItem to={`/users/${props.currentUser.id}/groups`} icon="card-checklist" label={t('groups')} />
                    <FooterItem to={`/users/${props.currentUser.id}`} icon="person" label={t('profile')} active={profileItemActive}/>
                    {['admin', 'superadmin'].includes(props.currentUser.kind) && <div className="d-flex flex-column text-center">
                        <a href="/admin"><BsIcon icon="key" classNames="fs-2 footer_item text-secondary"/></a>
                        <span className="footer_text footer_item text-secondary mt-1">{t('admin.admin')}</span>
                    </div>}
                </> :
                    <FooterItem to="/" icon="box-arrow-in-right" label={t('login')} active={location.pathname === "/" || location.pathname.startsWith('/sign_in')}/>}
            </div>
        </Nav>
    }
}

export default MobileFooter;