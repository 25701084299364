import React, {useEffect, useState} from "react";
import {Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {performAjaxRequest} from "../../helpers";
import BsCard from "../../components/cards/bsCard";
import TextSmall from "../../components/textSmall";
import {useTranslation} from "react-i18next";
import ReportingFilter from "../../components/forms/filters/reportingFilters";
import BsIcon from "../../components/bsIcon";
import {Alert} from "react-bootstrap";

function AdminReportsPage() {
    const { t } = useTranslation();
    const colorPalette = [
        "#6C9FEB",
        "#2C60AC",
        "#6FBE7C",
        "#538E5D",
        "#BF6F7F",
        "#934FB0",
        "#91969B",
        "#634B50",
        "#DBE339",
    ];


    const [data, setData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        return `${year}-${month < 10 ? `0${month}` : month}`;
    });
    const [selectedGroup, setSelectedGroup] = useState("all");

    useEffect(() => {
        performAjaxRequest(
            'GET',
            `/admin/reports/attendance_report_data`,
            {month: selectedMonth, group_id: selectedGroup},
            {success: response => setData(response)}
        )
    }, [selectedMonth, selectedGroup]);

    if (!data || !Array.isArray(data)) {
        return <p>{t('loading')}</p>;
    }

    const getWeekRange = (weekNum, month, year) => {
        const firstDayOfYear = new Date(year, 0, 1);
        const firstMonday = firstDayOfYear.getDate() + (1 - firstDayOfYear.getDay() + 7) % 7;

        const startDate = new Date(year, 0, firstMonday + (weekNum - 1) * 7);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const lastDayOfStartMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

        const startStr = (startDate.getMonth() !== endDate.getMonth() && startDate.getDate() === lastDayOfStartMonth.getDate() && month > startDate.getMonth())
            ? `1` : `${startDate.getDate()}`;

        const endStr = (endDate.getMonth() !== startDate.getMonth() && month < endDate.getMonth())
            ? `${lastDayOfStartMonth.getDate()}` : `${endDate.getDate()}`

        return startStr === endStr ? `${startStr}.` : `${startStr}-${endStr}`;
    };

    const aggregatedData = data.reduce((acc, {week_of_year, count}) => {
        let weekEntry = acc.find(entry => entry.week_of_year === week_of_year);
        if (!weekEntry) {
            weekEntry = {week_of_year, total: 0};
            acc.push(weekEntry);
        }
        weekEntry.total += count;
        return acc;
    }, []);

    const formattedData = aggregatedData.reduce((acc, { week_of_year, total }) => {
        const date = new Date(selectedMonth)
        const weekRange = getWeekRange(week_of_year, date.getMonth(), date.getFullYear());
        acc.push({ week_of_year: weekRange, total });
        return acc;
    }, []);

    const getYTicks = (data) => {
        const max = Math.max(...data.map(d => Object.values(d).slice(1).reduce((acc, val) => acc + val, 0)));
        return Array.from({ length: max + 2 }, (_, i) => i); // Create array [0, 1, ..., max]
    };

    return <div>
        <ReportingFilter selectedMonth={selectedMonth}
                         setSelectedMonth={setSelectedMonth}
                         selectedGroup={selectedGroup}
                         setSelectedGroup={setSelectedGroup} />

        {formattedData?.length > 0 ?
            <BsCard noStyle={true} title={<TextSmall text={t("admin.reports.attendance")}/>}>
                <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={formattedData} margin={{ top: 20, right: -10, left: -50, bottom: 10 }} padding={{left: 30}}>
                        <XAxis dataKey="week_of_year" tick={({ x, y, payload }) => (
                            <text x={x} y={y} dy={16} className="small" textAnchor="middle" fill="#666">
                                {payload.value}
                            </text>
                        )} />
                        <YAxis ticks={getYTicks(aggregatedData)} tick={false} />
                        <Bar dataKey="total" fill={colorPalette[0]} barSize={50}>
                            <LabelList dataKey="total" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </BsCard>
            :
            <Alert variant="info" className="col-md-6 small p-2 text-center">
                <BsIcon icon="search" />
                <hr />
                {t('admin.reports.not_found')}
                <br />
                <Alert.Link className="text-decoration-underline" href="/admin/reports">{t("admin.reports.remove_filters")}</Alert.Link>.
            </Alert>
        }
    </div>
}

export default AdminReportsPage;