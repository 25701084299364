import React from "react";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";

function Name(props) {
    const { t } = useTranslation();

    return <>
        <Form.Group className="mb-3">
            <Form.Label>{ t('last_name') }*</Form.Label>
            <Form.Control onChange={props.handleInputChange}
                          value={props.formData.last_name || ""}
                          type="text" name="last_name" size='sm' className="bg-light text-dark"
                          autoFocus={props.autoFocus || false}
                          required />
            <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{ t('first_name') }*</Form.Label>
            <Form.Control onChange={props.handleInputChange}
                          value={props.formData.first_name || ""}
                          type="text" name="first_name" size='sm' className="bg-light text-dark"
                          required/>
            <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>
        </Form.Group>
    </>
}

export default Name;
